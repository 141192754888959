class CommonFns {
    static getFormattedDate(date: Date) {
        var objToday;

        if (date) {
            objToday = date;
        }
        else {
            objToday = new Date();
        }

        var dayOfMonth: any = today + (objToday.getDate() < 10) ? '0' + objToday.getDate() : objToday.getDate(),
            domEnd: string = dayOfMonth == 1 || dayOfMonth == 21 || dayOfMonth == 31 ? 'st' : dayOfMonth == 2 || dayOfMonth == 22 ? 'nd' : dayOfMonth == 3 || dayOfMonth == 23 ? 'rd' : 'th',
            months = new Array('January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'),
            weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
            curMonth = months[objToday.getMonth()],
            curYear = objToday.getFullYear(),
            day = weekday[date.getDay()];

        var today = `${day} ${dayOfMonth}${domEnd} ${curMonth} ${curYear}`;

        return today;
    }

    static getFormattedTime(date: Date) {
        var objToday;

        if (date) {
            objToday = date;
        }
        else {
            objToday = new Date();
        }

        var curHour = objToday.getHours() > 12 ? objToday.getHours() - 12 : (objToday.getHours() < 10 ? "0" + objToday.getHours() : objToday.getHours()),
            curMinute = objToday.getMinutes() < 10 ? "0" + objToday.getMinutes() : objToday.getMinutes(),
            curMeridiem = objToday.getHours() > 12 ? "PM" : "AM";

        var today = `${curHour}:${curMinute} ${curMeridiem}`;

        return today;
    }

    static getNextXDaysShortDatesHtml(previousDays: number, futureDays: number): string[] {
        const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        let shortDates = [];

        for (let i: number = -previousDays; i <= futureDays; i++) {
            let date = new Date();
            date.setDate(date.getDate() + i);
            const day: string = weekday[date.getDay()];
            const dayOfMonth: number = date.getDate();
            const domEnd: string = (dayOfMonth == 1 || dayOfMonth == 21 || dayOfMonth == 31) ? 'st' :
                (dayOfMonth == 2 || dayOfMonth == 22) ? 'nd' :
                    (dayOfMonth == 3 || dayOfMonth == 23) ? 'rd' : 'th';

            shortDates.push(`<div data-date="${date.toISOString()}"><span class="name">${day}</span><span class="date">${dayOfMonth}${domEnd}</span></div>`);
        }

        return shortDates;
    }

    static getFormattedDateYYYYMMDD(date: Date) {
        const day: string = String(date.getDate()).padStart(2, '0');
        const month: string = String(date.getMonth() + 1).padStart(2, '0');
        const year: string = String(date.getFullYear());

        return `${year}/${month}/${day}`;
    }

    static getFormattedDateDDMMYYYYFromString(dateStr: string) {
        const date: Date = new Date(dateStr); 
        return CommonFns.getFormattedDateDDMMYYYY(date);
    }

    static getFormattedDateTimeDDMMYYYYFromString(dateStr: string) {
        const date: Date = new Date(dateStr);
        const formattedDate: string = CommonFns.getFormattedDateDDMMYYYY(date);
        const formattedTime: string = CommonFns.getFormattedTime(date);

        return `${formattedDate} ${formattedTime}`;
    }

    static getFormattedDateDDMMYYYY(date: Date) {
        const day: string = String(date.getDate()).padStart(2, '0');
        const month: string = String(date.getMonth() + 1).padStart(2, '0');
        const year: string = String(date.getFullYear());

        return `${day}/${month}/${year}`;
    }

    static getFormattedDateYYMMDD(date: Date) {
        const day: string = String(date.getDate()).padStart(2, '0');
        const month: string = String(date.getMonth() + 1).padStart(2, '0');
        const year: string = String(date.getFullYear());

        return `${year}-${month}-${day}`;
    }

    static getStartOfCurrentWeekDate(): Date {
        const now: Date = new Date();
        const dayOfWeek: number = now.getDay(); // 0 (Sunday) to 6 (Saturday)
        const startOfWeek: Date = new Date(now); // Clone the current date

        // Calculate the start of the week
        startOfWeek.setDate(now.getDate() - dayOfWeek);
        startOfWeek.setHours(0, 0, 0, 0); // Optional: Set the time to midnight

        return startOfWeek;
    }

    static getEndOfCurrentWeekDate(): Date {
        const now: Date = new Date();
        const dayOfWeek: number = now.getDay(); // 0 (Sunday) to 6 (Saturday)
        const diff: number = 6 - dayOfWeek; // Calculate days until end of week
        const endOfWeek: Date = new Date(now); // Clone the current date

        // Calculate the end of the week
        endOfWeek.setDate(now.getDate() + diff);
        endOfWeek.setHours(23, 59, 59, 999); // Set the time to end of the day

        return endOfWeek;
    }

    static getDayCount(startDate: string, endDate: string) : number {
        if (startDate && endDate) {
            if (startDate == endDate){
                return 1;
            }

            const start = new Date(startDate);
            const end = new Date(endDate);

            if (!isNaN(start.getTime()) && !isNaN(end.getTime())) {
                const diffTime = Math.abs(end.getTime() - start.getTime());
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                return diffDays;
            }
        }

        return 0;
    }
}

export default CommonFns;