// see https://mui.com/x/react-data-grid/getting-started/#installation MIT Licence
import * as React from 'react';
import { DataGrid, GridToolbar, GridRowsProp, GridColDef, GridEventListener, useGridApiRef, GridFilterModel } from '@mui/x-data-grid';

interface TransactionSearchResultsListProps {
    rows: GridRowsProp,
    columns: GridColDef[],
    transactionSelectedFn: Function
}

const TransactionListComponent: React.FC<TransactionSearchResultsListProps> = ({ rows, columns, transactionSelectedFn }) => {
    const handleRowClickEvent: GridEventListener<'rowClick'> = params => {
        transactionSelectedFn(params.row.id);
    };

    return (
        <div className="data-grid">
            {rows && columns &&
                <DataGrid
                    rows={rows}
                    columns={columns}
                    onRowClick={handleRowClickEvent}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                        toolbar: { showQuickFilter: true, }
                    }}
                />}
        </div>
    )
}

export default TransactionListComponent;