import React, { useState, useEffect } from 'react';
import { IPurchaseOrder, IPurchaseOrderItem } from '../interfaces/purchaseOrder';
import CommonFns from '../common/commonFns';

interface POProps {
    transaction: any
}

const POComponent: React.FC<POProps> = ({ transaction }) => {
    const [transactionState, setTransactionState] = useState(transaction);
    const [po, setPO] = useState(null);

    const CreateSiteAddress = (poSiteAddress: any) => {
        if (!poSiteAddress) {
            return "!Not Set";
        }

        let address: string =
            `
            ${poSiteAddress.ADR_NAME ? poSiteAddress.ADR_NAME : ''}
            ${poSiteAddress.ADR_LINE1 ? ',' + poSiteAddress.ADR_LINE1 : ''}
            ${poSiteAddress.ADR_LINE2 ? ',' + poSiteAddress.ADR_LINE2 : ''}
            ${poSiteAddress.ADR_LINE3 ? ',' + poSiteAddress.ADR_LINE3 : ''}
            ${poSiteAddress.ADR_TOWN ? ',' + poSiteAddress.ADR_TOWN : ''}
            ${poSiteAddress.ADR_COUNTY ? ',' + poSiteAddress.ADR_COUNTY : ''}
            ${poSiteAddress.ADR_POSTCODE ? ',' + poSiteAddress.ADR_POSTCODE : ''}
        `;

        // Remove any leading or trailing ,
        return address.replace(/(^,*)|(,*$)/g, '');
    };

    const CreateName = (poSiteContact: any) => {
        if (!poSiteContact || !poSiteContact.CCT_FIRST_NAMES && !poSiteContact.CCT_SURNAME) {
            return "!Not Set";
        }

        return `${poSiteContact.CCT_FIRST_NAMES ? poSiteContact.CCT_FIRST_NAMES : ''} ${poSiteContact.CCT_SURNAME ? poSiteContact.CCT_SURNAME : ''}`.trim();
    };

    // Kick-of Effect
    useEffect(() => {
        if (transaction) {
            const j = transaction.originalDataExtractJson.replace(/\n/g, '');
            const transactionHeader = JSON.parse(j);

            if (transactionHeader) {
                if (transactionHeader.Json) {
                    const j1 = transactionHeader.Json.replace(/\n/g, '');
                    const originalPO = JSON.parse(j1);
                    const poDeliveryItem: any = originalPO.poHireItems.find((x: any) => x.HIT_EQUIPMENT_CODE === '_TRSP' && x.HIT_CURRENT_STOCK_NUMBER === '_DELIVERY');
                    const poCollectionItem: any = originalPO.poHireItems.find((x: any) => x.HIT_EQUIPMENT_CODE === '_TRSP' && x.HIT_CURRENT_STOCK_NUMBER === '_COLLECTION');
                    const poHireItems: any = originalPO.poHireItems.filter((x: any) => x.HIT_EQUIPMENT_CODE === 'XH');
                    const hireStartDate: any = poDeliveryItem ? poDeliveryItem.HCT_START_DATE : null;
                    const hireEndDate: any = poCollectionItem ? poCollectionItem.HCT_PLANNED_END_DATE : null;
                    const chargePeriod: any = hireStartDate !== null && hireEndDate != null ? `${CommonFns.getDayCount(hireStartDate, hireEndDate)} day/s` : "!No Start/End Dt";
                    const dueDate: any = originalPO.po.POR_DUE_DATE !== null ? `${CommonFns.getFormattedDateDDMMYYYYFromString(originalPO.po.POR_DUE_DATE)}` : '!Not Set';
                    const dueTime: any = originalPO.po.POR_DUE_TIME !== null ? `pre - ${originalPO.po.POR_DUE_TIME}` : '';

                    const po: IPurchaseOrder = {
                        poNo: originalPO.po.POR_ID,
                        dateSent: `${CommonFns.getFormattedDateDDMMYYYYFromString(originalPO.po.POR_ORDER_DATE)}`,
                        raisedBy: originalPO.po.USR_NAME,
                        poRef: originalPO.po.POR_USER_PO_NUMBER,
                        partner: originalPO.po.SUP_NAME,
                        branch: originalPO.po.SUP_TOWN,
                        dateRequired: `${dueDate} ${dueTime}`,
                        partnerContact: originalPO.po.POR_SUPPLIER_CONTACT,
                        hireStartDate: hireStartDate != null ? `${CommonFns.getFormattedDateDDMMYYYYFromString(hireStartDate)}` : '!Not Set',
                        hireEndDate: hireEndDate != null ? `${CommonFns.getFormattedDateDDMMYYYYFromString(hireEndDate)}` : '!Not Set',
                        chargePeriod: chargePeriod,
                        itemsRequested: null,
                        customer: {
                            name: originalPO.po.CST_NAME,
                            accountNo: originalPO.po.CST_ACCOUNT_NUMBER,
                            contractNo: originalPO.po.HCT_USER_CONTRACT_NUMBER
                        },
                        site: {
                            name: originalPO.poSiteAddress.ADR_NAME,
                            address: CreateSiteAddress(originalPO.poSiteAddress),
                            directions: originalPO.poSiteAddress.ADR_DIRECTIONS,
                            contactName: CreateName(originalPO.poSiteContact),
                            deliveryNotes: poHireItems && poHireItems.length > 0 ? poHireItems[0].HCT_DELIVERY_NOTES : ''
                        }
                    };

                    po.itemsRequested = poHireItems && poHireItems.length > 0 ? poHireItems.map((x: any) => ({
                        productName: x.HIT_DESCRIPTION,
                        hpCode: x.HIT_CURRENT_STOCK_NUMBER,
                        fleetNo: x.HIT_XH_PLANT_NUMBER,
                        qty: x.HIT_QUANTITY,
                        hirePeriod: chargePeriod,
                        price: x.HIT_CHARGE_AMOUNT,
                        deliveryCharge: poDeliveryItem ? poDeliveryItem.HIT_CHARGE_AMOUNT : '!NotFound',
                        collectionCharge: poCollectionItem ? poCollectionItem.HIT_CHARGE_AMOUNT : '!NotFound',
                        total: 0
                    })) : [];

                    setPO(po);
                }
            }
        }
    }, [transactionState]);

    return (
        <>
            {po &&
                <div>
                    <h2 className="transaction__title">Customer / Contract</h2>
                    <table className="table" cellPadding="0" cellSpacing="0">
                        <tbody>
                            <tr>
                                <td className="label cell--md">Name:</td>
                                <td>{po.customer.name}</td>
                            </tr>
                            <tr>
                                <td className="label">Account No:</td>
                                <td>{po.customer.accountNo}</td>
                            </tr>
                            <tr>
                                <td className="label">Contract No:</td>
                                <td>{po.customer.contractNo}</td>
                            </tr>
                        </tbody>
                    </table>
                    <h2 className="transaction__title__title">XH Partner</h2>
                    <table className="table" cellPadding="0" cellSpacing="0">
                        <tbody>
                            <tr>
                                <td className="label cell--md">Date Sent:</td>
                                <td>{po.dateSent}</td>
                                <td className="label cell--md">Raised By:</td>
                                <td>{po.raisedBy}</td>
                            </tr>
                            <tr>
                                <td className="label">PO Ref:</td>
                                <td colSpan={3}>{po.poRef}</td>
                            </tr>
                            <tr>
                                <td className="label">Partner:</td>
                                <td colSpan={3}>{po.partner}</td>
                            </tr>
                            <tr>
                                <td className="label">Branch:</td>
                                <td colSpan={3}>{po.branch}</td>
                            </tr>
                            <tr>
                                <td className="label">Partner Contact:</td>
                                <td colSpan={3}>{po.partnerContact}</td>
                            </tr>
                            <tr>
                                <td className="label">Date Required:</td>
                                <td colSpan={3}>{po.dateRequired}</td>
                            </tr>
                            <tr>
                                <td className="label">Hire Start Date:</td>
                                <td>{po.hireStartDate}</td>
                                <td className="label">Hire End Date:</td>
                                <td>{po.hireEndDate}</td>
                            </tr>
                            <tr>
                                <td className="label">Charge Period:</td>
                                <td colSpan={3}>{po.chargePeriod}</td>
                            </tr>
                        </tbody>
                    </table>
                    <h2 className="transaction__title">Items Requested</h2>
                    {po.itemsRequested.map((poItem: IPurchaseOrderItem, index: number) => (
                        <table className="table" cellPadding="0" cellSpacing="0" key={ 'poItm_' + index }>
                            <thead>
                                <tr>
                                    <th>Product</th>
                                    <th>Code</th>
                                    <th>Fleet No.</th>
                                    <th>Qty</th>
                                    <th>Hire Period</th>
                                    <th>Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{poItem.productName}</td>
                                    <td>{poItem.hpCode}</td>
                                    <td>{poItem.fleetNo}</td>
                                    <td>{poItem.qty}</td>
                                    <td>{poItem.hirePeriod}</td>
                                    <td>&#163;&nbsp;{poItem.price}</td>
                                </tr>
                                <tr>
                                    <td className="label">Delivery Charge</td>
                                    <td colSpan={4}></td>
                                    <td>&#163;&nbsp;{poItem.deliveryCharge}</td>
                                </tr>
                                <tr>
                                    <td className="label">Collection Charge</td>
                                    <td colSpan={4}></td>
                                    <td>&#163;&nbsp;{poItem.collectionCharge}</td>
                                </tr>
                                <tr>
                                    <td className="label">Total:</td>
                                    <td colSpan={4}></td>
                                    <td>&#163;&nbsp;{poItem.total}</td>
                                </tr>
                            </tbody>
                        </table>
                    ))}
                    <h2 className="transaction__title">Site</h2>
                    <table className="table" cellPadding="0" cellSpacing="0">
                        <tbody>
                            <tr>
                                <td className="label cell--md">Name:</td>
                                <td>{po.site.name}</td>
                            </tr>
                            <tr>
                                <td className="label">Address:</td>
                                <td>{po.site.address}</td>
                            </tr>
                            <tr>
                                <td className="label">Directions:</td>
                                <td>{po.site.directions}</td>
                            </tr>
                            <tr>
                                <td className="label">Contact Name:</td>
                                <td>{po.site.contactName}</td>
                            </tr>
                            <tr>
                                <td className="label">Partner Delivery Notes:</td>
                                <td>{po.site.deliveryNotes}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            }
        </>
    )
}

export default POComponent;